import React from "react";
import "../scss/blocks/_header.scss";

import logo from "../images/header/logo.png";

const Header = () => {
  return (
    <header className="header">
      <div className="container">
        <div className="header__inner">
          <div className="header__logo">
            <img
              src={logo}
              width="40px"
              height="40px"
              alt=""
              className="header__logo-img"
            />
            <div className="header__logo-name">Stablegram</div>
          </div>
          <a
            href="https://t.me/usdt_jobs"
            target="_blank"
            className="header__link"
          >
            Post a job
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
