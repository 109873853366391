import React from "react";
import logo from "../images/header/logo.png";
import tg from "../images/tg.png";

const Footer = () => {
  return (
    <>
      <div className="subscribe-mobile">
        <p className="subscribe-mobile__left">
          Join our official Telegram channel
        </p>
        <a
          href="https://t.me/+faB06IA5Qs8xOGUy"
          target="_blank"
          className="subscribe-mobile__btn"
        >
          <img src={tg} alt="" /> Subscribe
        </a>
      </div>

      <footer className="footer">
        <div className="container">
          <div className="footer__inner">
            <div className="footer__logo">
              <img
                src={logo}
                width="40px"
                height="40px"
                alt=""
                className="footer__logo-img"
              />
              <div className="footer__logo-name">Stablegram Inc.</div>
            </div>
            <p className="footer__p">2022. All rights reserved </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
