import React from "react";
import "../scss/blocks/_main.scss";

import img1 from "../images/main/1.png";
import img2 from "../images/main/2.png";
import img3 from "../images/main/3.png";
import img4 from "../images/main/4.png";
import img5 from "../images/main/5.png";
import img6 from "../images/main/6.png";
import img7 from "../images/main/7.png";
import img8 from "../images/main/8.png";

function Main() {
  return (
    <main className="main">
      <div className="container">
        <div className="main__inner">
          <h1 className="main__title">
            We help talent to find the most promising Web3 companies
          </h1>
          <div className="main__logos">
            <h2 className="main__logos-title">
              Find a crypto career you'll love{" "}
            </h2>
            <div className="main__logo">
              <div className="main__logos-logos">
                <img
                  src={img1}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img"
                />
                <img
                  src={img2}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img"
                />
                <img
                  src={img3}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img"
                />
                <img
                  src={img4}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img"
                />
                <img
                  src={img5}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img"
                />
                <img
                  src={img6}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img"
                />
                <img
                  src={img7}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img"
                />
                <img
                  src={img8}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img"
                />

                <img
                  src={img1}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img2}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img3}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img4}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img5}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img6}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img7}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img8}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img1}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img2}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img3}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img4}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img5}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img6}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img7}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img8}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img1}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img2}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img3}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img4}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img5}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img6}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img7}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img8}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img1}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img2}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img3}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img4}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img5}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img6}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img7}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img8}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img1}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img2}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img3}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img4}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img5}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img6}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img7}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img8}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img1}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img2}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img3}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img4}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img5}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img6}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img7}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
                <img
                  src={img8}
                  width="auto"
                  height="72px"
                  alt=""
                  className="main__logos-logos-img2"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Main;
